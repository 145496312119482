import React, { useEffect } from 'react'
import queryString from 'query-string'
import { navigate } from 'gatsby'

import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'

const REDIRECT_PATHS = {
  resetPassword: '/auth/reset-password',
  verifyEmail: '/auth/verify-email',
}

function AuthCallback() {
  useEffect(() => {
    const { mode, oobCode, email } = queryString.parse(window.location.search)

    navigate(REDIRECT_PATHS[mode] || '/', {
      replace: true,
      state: { code: oobCode, email },
    })
  }, [])

  return <LoadingImage></LoadingImage>
}

export default AuthCallback
